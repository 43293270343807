<template>
  <div class="header z-50">
    <div class="navbar-header">
      <b-navbar-nav class="flex flex-wrap flex-row">
        <b-nav-item
          to="/positions"
          exact
        >
          <span>Positions</span>
        </b-nav-item>

        <b-nav-item
          to="/plants"
          exact
        >
          <span>Plants</span>
        </b-nav-item>

        <b-nav-item
          to="/plant_groups"
          exact
        >
          <span>Plant Groups</span>
        </b-nav-item>

        <b-nav-item
          to="/operations"
          exact
        >
          <span>Operations</span>
        </b-nav-item>

        <b-nav-item
          to="/moves"
          exact
        >
          <span>Moves</span>
        </b-nav-item>
      </b-navbar-nav>
    </div>
    <ul class="d-flex flex-grow-1 navbar-nav">
      <li class="flex-grow-1"></li>
      <b-nav-item-dropdown class="navbar-user" right>
        <template #button-content>
          <span>{{ AccountName }}</span>
        </template>
        <b-dropdown-item to="/admin">Admin</b-dropdown-item>
        <b-dropdown-item to="/account/logout">Sign out</b-dropdown-item>
      </b-nav-item-dropdown>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  components: {},
  computed: {
    AccountName() {
      return this.account.Name ?? this.account.User;
    },
  },
  data() {
    return {
      account: this.$store.state.account,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar-header {
  width: 100%;
}
.b-nav-dropdown::v-deep {
  & .dropdown-menu-right {
    left: -8px !important;
  }

  & .dropdown-toggle::after {
    vertical-align: middle;
  }
}

.navbar-nav::v-deep .router-link-active {
  font-weight: bold;
}
</style>
